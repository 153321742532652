<template>
  <div class="reel-wrapper">
    <div class="reel-container">
      <div class="reel">
        <iframe
          :src="url"
          frameborder="0"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "https://www.youtube.com/embed/FS1B0ejKukQ",
    };
  },
};
</script>

<style scoped>
.reel-wrapper {
  margin: 0 auto;
  max-width: 48em;
  padding: 0 20px;
}

.reel-container {
  /* display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em; */

  position: relative;
  padding-bottom: 57.5%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  /* max-width: 48em; */
  height: auto;
}

.reel {
  /* width: 100%;
  height: 100%; */
  /* width: 48em; */
  /* height: 27em; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reel iframe {
  width: 100%;
  height: 100%;
}
</style>
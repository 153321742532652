<template>
  <div class="main-content">
    <div class="sub-title">LATEST WORKS</div>
    <hr />
    <!-- <div class="video-categories">
      <div
        class="video-category"
        :class="isSelected('movies') ? 'video-category-selected' : ''"
        @click="selectCategory('movies')"
      >Movies</div>
      <div
        class="video-category"
        :class="isSelected('games') ? 'video-category-selected' : ''"
        @click="selectCategory('games')"
      >Video games</div>
    </div>
    <div class="video-container" v-show="category === 'movies'" v-for="content in contentDataMovies" :key="content.videoId">
      <VideoBlock :content="content" />
    </div>
    <div class="video-container" v-show="category === 'games'" v-for="content in contentDataGames" :key="content.videoId">
      <VideoBlock :content="content" />
    </div> -->
    <div
      class="video-container"
      v-for="content in contentData"
      :key="content.videoId"
    >
      <VideoBlock :content="content" />
    </div>
  </div>
</template>

<script>
import VideoBlock from "../components/VideoBlock.vue";
export default {
  data() {
    return {
      contentData: [
        {
          title: "Next Flight Home",
          description:
            '<strong class="first">First Prize</strong> of the Indie Film Music Contest 2021. <br/> <br/>\
          Next Flight Home is a short film that follows a pigeon as he makes the biggest decision of his life. \
          He has everything a pigeon could ever want. But when he meets a beautiful \
          dove one day on the street, he realizes how much better and more exciting his life is with her.',
          videoId: "https://www.youtube.com/embed/gw0UzBCl-F0?start=5",
          type: "Short movie",
          style: "Orchestral / Big Band Jazz",
          year: "2021",
        },
        {
          title: "Maija",
          description:
            "Composition for the OST Challenge's animated movie 2022. <br/> <br/>\
          In a lost village in the Nordic islands, a little girl is waiting for her father, gone fishing at sea.",
          videoId: "https://fast.wistia.net/embed/iframe/yudrz3sbid?videoFoam=true",
          type: "Short Movie",
          style: "Modern Orchestral",
          year: "2022",
        },
        {
          title: "The Ultimate Space Race",
          description:
            "Composition for a comedic animated movie recounting the events of the space race that took place between the USA and \
            the USSR from 1955 until 1969.<br/> <br/>\
            Directed by Jake Wegesin at <a href='https://ambient.press/space-race' target='_blank'>Ambient Press</a>.",
          videoId: "https://player.vimeo.com/video/710240034?h=f2b0756172",
          type: "Short movie",
          style: "Big Band Jazz",
          year: "2022",
        },
        {
          title: "Sprite Fright",
          description:
            "Composition for the Score Relief's animated movie 2022. <br/> <br/>\
          When a group of rowdy teenagers trek into an isolated forest, they discover peaceful mushroom creatures that turn out to be an unexpected force of nature.",
          videoId: "https://www.youtube.com/embed/SfQpwTQeUHE?start=4",
          type: "Short movie",
          style: "Orchestral",
          year: "2021",
        },
        {
          title: "Klaus",
          description:
            "Composition for the ScoreLive London's animated movie 2022. <br/> <br/>\
          Klaus and his mailman companion desperately try to escape from mean villagers who want to destroy all the Christmas gifts,\
          while solving their personal issues.",
          videoId: "https://www.youtube.com/embed/CunSChEN4PI?start=5",
          type: "Movie (extract)",
          style: "Orchestral",
          year: "2022",
        },
        {
          title: "I Catch You",
          description:
            "Composition for the Score Relief's short movie 2022. <br/> <br/>\
          When enough is enough you shine a spotlight. Becoming brave not bitter to RISE UP.<br/>\
          Poem written by Colette Brown, with Four Of Wands Productions.",
          videoId: "https://www.youtube.com/embed/33ytmDeZVx8?start=3",
          type: "Short movie",
          style: "Orchestral",
          year: "2022",
        },
        {
          title: "Genesis",
          description:
            "Composition for the Berlin International Film Scoring Contest 2022.<br/><br/> Our protagonist lands on a foreign \
            planet finds an alien artifact and travels back into a time, where the planet was covered by a lush forest.",
          videoId: "https://www.youtube.com/embed/U_NlW3farCo?start=18",
          type: "Short movie",
          style: "Orchestral",
          year: "2021",
        },
        // {
        //   title: "RAN",
        //   description:
        //     "Composition for the Nikon Film Festival 2022. <br/> <br/>\
        //     Always suffering from Kelian's toxic friendship, Hugo will face — in spite of himself — strangeness to find Anna, the girl of his dream. <br/>\
        //     Directed by Ibrahim Bersanov.",
        //   videoId: "https://www.festivalnikon.fr/embed/2021/922",
        //   type: "Short movie",
        //   style: "Orchestral",
        //   year: "2021",
        // },
        // {
        //   title: "Et après toi ?",
        //   description:
        //     "Composition for the Nikon Film Festival 2022. <br/> <br/>\
        //     Louise's dream? To spend her life alongside the love of her life, Marcel. <br/>\
        //     Directed by Ysialine Brocard.",
        //   videoId: "https://www.festivalnikon.fr/embed/2021/1346",
        //   type: "Short movie",
        //   style: "Orchestral",
        //   year: "2021",
        // },
        {
          title: "Running Shapes",
          description:
            "Running Shapes is a mobile game where the player shapeshifts constantly and must go through \
          the correct walls to fit them perfectly. As the level goes by, everything goes faster, but you gain access to special bonuses \
          based on your current shape.",
          videoId: "https://www.youtube.com/embed/cVXAKeXYFg8",
          type: "Video Game",
          style: "Big Band",
          year: "2018",
        },
        {
          title: "Tarot Des Mages",
          description:
            "Tarot Des Mages is a computer strategy game. Each player has a deck of cards and tries to win by placing \
          them carefully on the board. If the colliding side of the card is lower, both tiles are won, until it is taken back again.",
          videoId: "https://www.youtube.com/embed/85G4jMx_HZo",
          type: "Video Game",
          style: "Orchestral",
          year: "2021",
        },
        {
          title: "Shake Island",
          description:
            "Shake Island is a computer memory-training game that takes place on an island where earthquakes \
          are more frequent than ever. \
          <br/>The player goes from house to house and tries to help the citizen by remembering where the furnitures \
          were placed before the big shaking! Afterwords, he must place everything as it was before.",
          videoId: "https://www.youtube.com/embed/0zBDdGn_pJc",
          type: "Video Game",
          style: "Big Band",
          year: "2019",
        },
        {
          title: "Flying Hoops",
          description:
            "Flying Hoops is a mobile game where the player embodies a butterfly trying to reach the end of \
          the level by flying through floating hoops to get the highest score. The music is bucolic and it immerses the player \
          in this beautiful yet somehow dangerous universe.",
          videoId: "https://www.youtube.com/embed/bh5sV_gOCV8",
          type: "Video Game",
          style: "Orchestral",
          year: "2019",
        },
      ],
      category: "movies",
    };
  },
  computed: {
    currentContentData() {
      return this.category === "movies"
        ? this.contentDataMovies
        : this.contentDataGames;
    },
  },
  methods: {
    isSelected(category) {
      return this.category === category;
    },
    selectCategory(category) {
      this.category = category;
    },
  },
  components: {
    VideoBlock,
  },
};
</script>

<style scoped>
.video-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sub-title {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 2.5em;
}

.video-categories {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  padding-top: 1em;
}

.video-category {
  margin: 0 1em 0.5em;
}

.video-category:hover {
  cursor: pointer;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.video-category-selected {
  border-bottom: 1px solid black;
}

.video-category-selected:hover {
  cursor: pointer;
  border-bottom: 1px solid black;
}
</style>